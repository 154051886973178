import { navigate } from "gatsby"
import React from "react"
import { handleKeyUp } from "../../utils"
import { getColor } from "./utils"

const GridCellQuote = ({ video_link, profileUri, color, quote }) => {
  return (
    <div
      className="cell-hover-quote"
      onClick={() => {
        video_link && video_link.url && navigate(`/visiting-room/${profileUri}`)
      }}
      onKeyUp={(ev) => {
        handleKeyUp(ev, () => {
          video_link &&
            video_link.url &&
            navigate(`/visiting-room/${profileUri}`)
        })
      }}
      role="link"
      tabIndex="0"
    >
      {!video_link || !video_link.url ? (
        <p style={{ opacity: 0.8 }}>Profile not available yet.</p>
      ) : null}
      <div
        className={`quote ${getColor(color)}`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <span>{`"${quote}"`}</span>
      </div>
    </div>
  )
}

export default GridCellQuote
