import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./GridCellGeneric.module.css"

const GridCellGeneric = ({ image, brightness, classes, message }) => (
  <div
    className={`open grid-cell ${styles.gridCellGeneric} ${classes
      .split(" ")
      .map((cls) => styles[cls])
      .join(" ")}`}
  >
    <div className="cell-background">
      <GatsbyImage
        alt={"Blurred image of a generic profile picture"}
        image={image}
        imgStyle={{
          objectFit: "cover",
          filter: `grayscale(1) blur(20px) brightness(${brightness})`,
        }}
      />
    </div>
    {message && (
      <div className={styles.message}>
        <h3 className={styles.messageText}>{message}</h3>
        <div className="bottom"></div>
      </div>
    )}
  </div>
)

export default GridCellGeneric
