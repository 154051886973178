import { navigate } from "gatsby-link"
import React from "react"
import { handleKeyUp } from "../../utils"
import Play from "../Symbols/Play"
import "./GridCell.css"
import GridCellSoundButton from "./GridCellSoundButton"
import { getColor } from "./utils"

const GridCellNameTag = ({
  video_link,
  profileUri,
  isHover,
  fullName,
  isSoundEnabled,
  setSoundVolume,
  setSoundEnabled,
  soundVolume,
  color,
}) => {
  return (
    <h3
      className="name-tag"
      onClick={() => {
        video_link && video_link.url && navigate(`/visiting-room/${profileUri}`)
      }}
      onKeyUp={(ev) =>
        handleKeyUp(ev, () => {
          video_link &&
            video_link.url &&
            navigate(`/visiting-room/${profileUri}`)
        })
      }
      role="link"
      style={{
        cursor: "default",
      }}
    >
      <div
        className={`svg-wrapper ${isHover && "hovered"}`}
        style={{
          background: `var(--${getColor(color)}`,
        }}
      >
        <div className="name-wrap">
          <div className="name-play">
            <Play color={"var(--clr-black)"} />
          </div>
          <div className="text">{fullName}</div>
        </div>
      </div>
      <GridCellSoundButton
        color={color}
        isHover={isHover}
        isSoundEnabled={isSoundEnabled}
        setSoundEnabled={setSoundEnabled}
        setSoundVolume={setSoundVolume}
        soundVolume={soundVolume}
      />
    </h3>
  )
}

export default GridCellNameTag
