import { get as getValue } from "lodash"
import { useMemo } from "react"

const useProfiles = (props) => {
  const profiles = useMemo(
    () =>
      getValue(props, "data.allPrismicProfile.edges", []).map(
        (profile) => profile.node.data
      ),
    [props]
  )

  const images = useMemo(() => getValue(props, "data.images", []), [props])

  return {
    profiles,
    loading: false,
    images,
  }
}

export default useProfiles
